<template>
    <v-app id="inspire">
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md10 lg10 xl8>
                    <v-form id="form_notifications" ref="form" v-model="valid" lazy-validation>
                        <v-card>
                            <v-card-title>
                                    <!--<span class="subtitle-1"><v-icon left>mdi-filter</v-icon> Kriterij pošiljanja</span>-->
                                    <span class="subtitle-1"><v-icon left>mdi-filter</v-icon> Kriterij pošiljanja</span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="toggleCardContent('card1')">
                                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">
                                <span class="body-2 font-weight-regular"><v-icon class="" medium color="secondary" left>mdi-information</v-icon> {{ searchCriteriaText }}</span>
                                <div class="div__divider__20"></div>

                                <!-- zap. št. oz id obvestila -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Zaporedna številka</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="notification_id"
                                                label="Zaporedna številka obvestila"
                                                prepend-icon="mdi-numeric"
                                                readonly
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                
                                <!-- oznaka obvestila -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Oznaka obvestila</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="notification_tag"
                                                label="Oznaka obvestila"
                                                prepend-icon="mdi-pound"
                                                readonly
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- datumska omejitev -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Veljavnost dovolilnice (od - do)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- začetni datum -->
                                            <v-menu
                                                ref="start_date_menu"
                                                :close-on-content-click="false"
                                                v-model="start_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_start_date"
                                                
                                                transition="scale-transition"
                                                offset-y
                                                
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_start_date_formatted"
                                                        label="Datum od"
                                                        prepend-icon="mdi-calendar-today"
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="startDateMenuSave(restriction_start_date)" v-model="restriction_start_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- končni datum -->
                                            <v-menu
                                                ref="end_date_menu"
                                                :close-on-content-click="false"
                                                v-model="end_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_end_date"
                                                
                                                transition="scale-transition"
                                                offset-y
                                                
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_end_date_formatted"
                                                        label="Datum do"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="endDateMenuSave(restriction_end_date)" v-model="restriction_end_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- tip dovolilnice -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Tip dovolilnice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_permit_type"
                                            :items="permitTypes"
                                            label="Izberi tip dovolilnice"
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-format-list-bulleted-type"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            no-data-text="Ni podatkov za prikaz"
                                            required
                                            :rules="permit_type_rules"
                                        ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- stauts dovolilnice -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Status dovolilnic</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_permit_statuses"
                                            :items="permitStatuses"
                                            label="Izberi statuse dovolilnice"
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-state-machine"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            no-data-text="Ni podatkov za prikaz"
                                            multiple
                                        ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- tipi uporabnikov -->
                                <v-container grid-list-xl text-x-center align-center>
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Tip uporabnika</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_user_types"
                                            :items="userTypes"
                                            label="Izberi tip uporabnika"
                                            chips
                                            clearable
                                            multiple
                                            deletable-chips
                                            prepend-icon="mdi-format-list-bulleted-type"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            no-data-text="Ni podatkov za prikaz"
                                        ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- izbrane blagajne -->
                                <v-container grid-list-xl text-x-center align-center v-if="showParkingLots">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-2 pl-0"><strong>Izberi parkomate</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_parking_lots"
                                            :items="parkingLots"
                                            label="Izberi parkomate"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-parking"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            no-data-text="Ni podatkov za prikaz"
                                        ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- izbrani potopni stebrički -->
                                <v-container grid-list-xl text-x-center align-center v-if="showPillars">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-2 pl-0"><strong>Izberi potopne stebričke</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_pillars"
                                            :items="pillars"
                                            label="Izberi potopne stebričke"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-pillar"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            no-data-text="Ni podatkov za prikaz"
                                        ></v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-cogs</v-icon>
                                <v-toolbar-title class="body-2">Nastavitve obveščanja</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('card2')">
                                    <v-icon>{{ card2 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-show="card2">
                                <span class="body-2 font-weight-regular"><v-icon class="" medium color="secondary" left>mdi-information</v-icon> {{ settingsNotificationText }}</span>
                                <div class="div__divider__20"></div>
                                <v-textarea
                                outlined
                                name="input-7-4"
                                label="Vnesite željeno besedilo"
                                v-model="notification_text"
                                counter
                                :rules="notification_text_rules"
                                required
                                ></v-textarea>
                                <v-radio-group v-model="notification_schedule_type" row>
                                    <v-radio label="Pošlji obvestila takoj" value="1"></v-radio>
                                    <v-radio label="Dodaj v vrsto za pošiljanje" value="2"></v-radio>
                                </v-radio-group>

                                <!-- datum pošiljanja obvestil -->
                                <v-container grid-list-xl text-x-center align-center v-if="notification_schedule">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Datum pošiljanja obvestil</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-menu
                                                ref="notification_schedule_date_menu"
                                                :close-on-content-click="false"
                                                v-model="notification_schedule_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="notification_schedule_date"
                                                
                                                transition="scale-transition"
                                                offset-y
                                                
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="notification_schedule_date_formatted"
                                                        label="Datum pošiljanja obvestil"
                                                        prepend-icon="mdi-calendar-today"
                                                        readonly
                                                        clearable
                                                        :rules="notification_schedule_type == 2 ? notification_schedule_date_rules : []"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="notificationsDateMenuSave(notification_schedule_date)" v-model="notification_schedule_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <!-- čas pošiljanja obvestil -->
                                <v-container grid-list-xl text-x-center align-center v-if="notification_schedule">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Čas pošiljanja obvestil</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-menu
                                                ref="notification_schedule_time_menu"
                                                v-model="notification_schedule_time_menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="notification_schedule_time"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="notification_schedule_time"
                                                    label="Čas pošiljanja obvestil"
                                                    prepend-icon="mdi-clock-outline"
                                                    readonly
                                                    v-on="on"
                                                    clearable
                                                    :rules="notification_schedule_type == 2 ? notification_schedule_time_rules : []"
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                color="secondary"
                                                format="24hr"
                                                v-if="notification_schedule_time_menu"
                                                v-model="notification_schedule_time"
                                                
                                                @click:minute="$refs.notification_schedule_time_menu.save(notification_schedule_time)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            <v-divider v-show="card2"></v-divider>
                            <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn @click="sendNotifications()" color="success" block outlined style="margin-bottom:10px;margin-top:10px;"><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                                <!--<v-btn @click="search()" color="indigo" block outlined style="margin-bottom:10px;"><v-icon left>mdi-magnify</v-icon>Prikaz dovolilnic</v-btn>-->
                                <v-btn @click="cancel()" color="error" block outlined style="margin-bottom:10px;"><v-icon left>mdi-refresh</v-icon>Prekliči</v-btn>
                                <v-btn @click="deleteNotification()" color="error" block outlined style="margin-bottom:10px;"><v-icon left>mdi-close</v-icon>Izbriši</v-btn>
                            </v-card-text>
                            <v-card-text v-else>
                                <v-btn @click="sendNotifications()" color="success" text><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                                <!--<v-btn @click="search()" color="indigo" text><v-icon left>mdi-magnify</v-icon>Prikaz dovolilnic</v-btn>-->
                                <v-btn @click="cancel()" color="error" text><v-icon left>mdi-refresh</v-icon>Prekliči</v-btn>
                                <v-btn @click="deleteNotification()" color="error" text><v-icon left>mdi-close</v-icon>Izbriši</v-btn>
                                
                                
                            </v-card-text>
                        </v-card>
                    </v-form>
                </v-flex>
            </v-layout>
        </v-container>

        <!-- dialog za shranjevanje -->
        <v-dialog v-model="dialog" persistent max-width="490">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <v-icon text>mdi-delete</v-icon>
                    {{ dialog_text }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click.native="dialogCancel()">Prekliči</v-btn>
                    <v-btn text :loading="loading" color="success" @click.native="dialogConfirm()">Nadaljuj</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- dialog za brisanje -->
        <v-dialog v-model="delete_dialog" persistent max-width="490">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <v-icon text>mdi-delete</v-icon>
                    {{ delete_dialog_text }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click.native="deleteDialogCancel()">Prekliči</v-btn>
                    <v-btn text :loading="loading" color="success" @click.native="deleteDialogConfirm()">Nadaljuj</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate, randomTagStringGenerator } from '@/helpers/utilities'

export default {

    data: () => ({
        valid: true,
        dialog_text:'Ste prepričani da želite dodati poslati obvestila?',
        delete_dialog_text: 'Ste prepričani da želite izbrisati izbrano obveščanje?',
        dialog:false,
        delete_dialog: false,
        loading: false,
        card1: true,
        card2: true,
        card3: true,
        card4: true,
        notification_id: null,
        notification_uuid: null,
        notification_tag: null,
        restriction_start_date: null,
        restriction_end_date:null,
        restriction_start_date_formatted: null,
        restriction_end_date_formatted: null,
        start_date_menu: false,
        end_date_menu:false,

        selected_permit_type: null,
        selected_user_types: null,
        selected_permit_statuses: null,
        selected_parking_lots: null,
        selected_pillars: null,

        permitTypes: [],
        userTypes: [],
        parkingUserTypes: [],
        promenadaUserTypes: [],
        permitStatuses: [],
        parkingLots: [],
        pillars: [],

        searchCriteriaText: 'Z izbiro naslednjih polj določite, katerim dovolilnicam bodo poslana obvestila.',
        settingsNotificationText : 'V spodnje polje vpisano besedilo bo poslano preko SMS sporočil vsem uporabnikom izbranih dovolilnic.',
        notification_text: null,
        notification_schedule_type: '1',
        notification_schedule: false,
        notification_schedule_date: null,
        notification_schedule_date_formatted: null,
        notification_schedule_date_menu: false,
        notification_schedule_time: null,
        notification_schedule_time_menu: false,
        notification_status: null,
        active_record: true,

        notification_text_rules: [
            v => (!!v) || 'Vnos besedila je obvezen',
        ],

        permit_type_rules: [
            v => (!!v) || 'Polje tip dovolilnice je obvezno',
        ],

        notification_schedule_date_rules: [
            v => (!!v) || 'Vnos datuma pošiljanja obvestil je obvezen',
        ],

        notification_schedule_time_rules: [
            v => (!!v) || 'Vnos časa pošiljanja obvestil je obvezen',
        ]
    }),

     watch: {
        restriction_start_date (val) {
            this.restriction_start_date_formatted = formatDate(val)
        },

        restriction_end_date (val) {
            this.restriction_end_date_formatted = formatDate(val)
        },

        notification_schedule_date (val) {
            this.notification_schedule_date_formatted = formatDate(val)
        },

        notification_schedule_type (val) {
            if(val == '2') {
                this.notification_schedule = true
            } else {
                this.notification_schedule = false
            }
        },

        selected_permit_type: {
            deep: true,
            handler(val) {

                this.selected_user_types = null
                this.selected_parking_lots = null
                this.selected_pillars = null

                if(val != null && val != undefined)
                {
                    if(val.type == 1) {
                        this.userTypes = this.parkingUserTypes
                    }

                    if(val.type == 2) {
                        this.userTypes = this.promenadaUserTypes
                    }
                } else {
                    this.userTypes = []
                }
            }
        }


    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        showParkingLots() {
            if(this.selected_permit_type != null && this.selected_permit_type != undefined) {
                if(this.selected_permit_type.type == 1) {
                    return true
                }
            }

            return false
        },

        showPillars() {
            if(this.selected_permit_type != null && this.selected_permit_type != undefined) {
                if(this.selected_permit_type.type == 2) {
                    return true
                }
            }

            return false;
        },
    },

    methods: {
        startDateMenuSave(value) {
            this.$refs.start_date_menu.save(value)
            this.start_date_menu = false
        },

        endDateMenuSave(value) {
            this.$refs.end_date_menu.save(value)
            this.end_date_menu = false
        },

        notificationsDateMenuSave(value) {
            this.$refs.notification_schedule_date_menu.save(value)
            this.notification_schedule_date_menu = false
        },

        toggleCardContent(card) {
            if(card === 'card1') {
                this.card1 = !this.card1
            }

            if(card === 'card2') {
                this.card2 = !this.card2
            }

            if(card === 'card3') {
                this.card3 = !this.card3
            }

            if(card === 'card4') {
                this.card4 = !this.card4
            }
        },

        createDataObject() {
            return {
                notification_id: this.notification_id,
                notification_uuid: this.notification_uuid,
                notification_tag: this.notification_tag,
                restriction_start_date: this.restriction_start_date,
                restriction_end_date: this.restriction_end_date,
                permit_type_uuid: (this.selected_permit_type != null && this.selected_permit_type != undefined) ? this.selected_permit_type.id : null,
                permit_statuses: (this.selected_permit_statuses != null) ? this.selected_permit_statuses : [],
                user_types: (this.selected_user_types != null) ? this.selected_user_types : [],
                notification_text: this.notification_text,
                notification_schedule_type: this.notification_schedule_type,
                notification_schedule_date: this.notification_schedule_date,
                notification_schedule_time: this.notification_schedule_time,
                active_record: (this.active_record == 1 || this.active_record == true) ? 1 : 0,
                selected_parking_lots: (this.selected_parking_lots != null) ? this.selected_parking_lots : [],
                selected_pillars: (this.selected_pillars != null) ? this.selected_pillars : []
            }
        },

        getNotification() {
            var vm = this
            this.$store.dispatch("EDIT_NOTIFICATION", this.notification_uuid)
            .then(response => {
                //
                window.console.log(response.data.data)
                vm.notification_id = response.data.data.id
                vm.notification_tag = response.data.data.tag
                vm.restriction_start_date = response.data.data.restriction_start_date
                vm.restriction_end_date = response.data.data.restriction_end_date
                vm.selected_permit_type = {
                    id: response.data.data.permit_type.uuid,
                    name: response.data.data.permit_type.name,
                    type: response.data.data.permit_type.type
                }

                vm.selected_permit_statuses = []
                response.data.data.permit_statuses.forEach(permitStatus => {
                    
                    vm.selected_permit_statuses.push({
                        id: permitStatus.uuid,
                        name: permitStatus.name,
                        type: permitStatus.type
                    })
                })

                setTimeout(() => {
                    vm.selected_user_types = []
                    response.data.data.user_types.forEach(userType => {
                        vm.selected_user_types.push({
                            id: userType.uuid,
                            name: userType.name,
                            type: userType.type
                        })
                    })

                    vm.selected_parking_lots = []
                    response.data.data.selected_parking_lots.forEach(parkingLot => {
                        vm.selected_parking_lots.push({
                            id: parkingLot.uuid,
                            name: parkingLot.name,
                            type: parkingLot.type
                        })
                    })

                    vm.selected_pillars = []
                    response.data.data.selected_pillars.forEach(pillar => {
                        vm.selected_pillars.push({
                            id: pillar.uuid,
                            name: pillar.name,
                            type: pillar.type
                        })
                    })
                }, 150)

                vm.notification_text = response.data.data.notification_text
                if(response.data.data.notification_schedule_type != null) {
                    if(response.data.data.notification_schedule_type.type == 1) {
                        vm.notification_schedule_type = "1"
                    } else {
                        vm.notification_schedule_type = "2"
                    }
                }

                vm.notification_schedule_date = response.data.data.notification_schedule_date
                vm.notification_schedule_time = response.data.data.notification_schedule_time
            })
            .catch(error => {
                window.console.error("### Notifications.vue@getNotification ###");
                window.console.error(error)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Pri pridobivanju podatkov o obvestilu je prišlo do napake.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
            })
            .finally(() => {
                setTimeout(() => { 
                    vm.loading = false
                }, 400)
            })
        },

        updateNotification(dataToSend) {
            var vm = this
            vm.$store.dispatch('UPDATE_NOTIFICATION', dataToSend)
            .then(response => {
                //window.console.log(response)
                setTimeout(() => {
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Nastavitve obvestila so bile uspešno posodobljene.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                }, 700)
            })
            .catch(error => {
                window.console.error("### Notifications@dialogConfirm ###");
                window.console.error(error);
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Pri posodobitvi nastavitev obvestila je prišlo do napake.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
            })
            .finally(() => {
                vm.$store.commit('SET_LOADER', false)
                setTimeout(() => {
                    vm.loading = false
                    vm.$store.commit('SET_PROGRESS', false)
                }, 600)
            })
        },

        sendNotifications() {
            var vm = this
            //window.console.log(vm.createDataObject());
            if(this.$refs.form.validate()) {
                vm.dialog = true
            } else {
                vm.$vuetify.goTo('#form_notifications', {
                    duration: 1000,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
            }
            
        },

        dialogConfirm() {
            this.dialog = false
            var vm = this

            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.commit('SET_LOADER_TEXT', 'Shranjevanje nastavitev obveščanja je v teku...')
            vm.$store.commit('SET_LOADER', true)

            let dataToSend = vm.createDataObject();
            //window.console.log(dataToSend)
            setTimeout(() => {
                vm.updateNotification(dataToSend)
            }, 200)

        },

        dialogCancel() {
            this.dialog = false
            this.loading = false
        },

        deleteNotification() {
            this.delete_dialog = true
        },

        deleteDialogConfirm() {
            this.delete_dialog = false
            var vm = this

            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.commit('SET_LOADER_TEXT', 'Brisanje obveščanja je v teku...')
            vm.$store.commit('SET_LOADER', true)
            vm.$store.dispatch('DELETE_NOTIFICATION', vm.notification_uuid)
            .then(response => {
                //window.console.log(response)
                this.$store.commit('SET_LOADER', false)
                vm.$store.commit('SET_PROGRESS', false)
                
                this.$router.push({
                    name: 'admin_notifications_list'
                })

                setTimeout(() => {
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Obvestilo z oznako #" +vm.notification_tag + " je bilo uspešno odstranjeno.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                }, 900)
            })
            .catch(error => {
                window.console.error("### ERROR DELETE_NOTIFICATION ###")
                window.console.error(error)
                
                vm.$store.commit('SET_PROGRESS', false)
                this.$store.commit('SET_LOADER', false)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Pri brisanju obvestila z oznako #" + vm.notification_tag + " je prišlo do napake.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
            })
        },

        deleteDialogCancel() {
            this.delete_dialog = false
            this.loading = false
        },

        getResources() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            this.$store.commit('SET_LOADER_TEXT', 'Pridobivanje podatkov o obvestilu...')
            this.$store.commit('SET_LOADER', true)
            vm.$store.dispatch('ADMIN_RESOURCES')
            .then(response => {
                if(response.data.hasOwnProperty('user_types')) {
                    //window.console.log(response.data.user_types)
                    response.data.user_types.forEach(item => {
                        if(item.type == 1 || item.type == 2) {
                            vm.parkingUserTypes.push(item)
                        }
                        
                        if(item.type == 3 || item.type == 4 || item.type == 5 || item.type == 6 || item.type == 7) {
                            vm.promenadaUserTypes.push(item)
                        }
                    })
                }

                if(response.data.hasOwnProperty('permit_types')) {
                    response.data.permit_types.forEach(item => {
                        vm.permitTypes.push(item)
                    })
                }

                if(response.data.hasOwnProperty('permit_statuses')) {
                    response.data.permit_statuses.forEach(item => {
                        vm.permitStatuses.push(item)
                    })
                }

                if(response.data.hasOwnProperty('parking_lots')) {
                    response.data.parking_lots.forEach(item => {
                        vm.parkingLots.push(item)
                    })
                }

                if(response.data.hasOwnProperty('pillars')) {
                    response.data.pillars.forEach(item => {
                        vm.pillars.push(item)
                    })
                }

                if(vm.notification_uuid != null && vm.notification_uuid != undefined) {
                    vm.getNotification()
                }
            })
            .catch(error => {
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Pri pridobivanju podatkov o obveščanju je prišlo do napake.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
            })
            .finally(() => {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)
                    vm.$store.commit('SET_LOADER', false)
                }, 600)
            })
        },

        cancel() {
            if(!this.editNotification) {
                this.getResources()
            }
        },

        setPermitTypes() {
            var vm = this
            this.$store.getters.permitTypes.forEach(item => {
                //window.console.log(item)
                vm.permitTypes.push(item)
            })
        },

        setPermitStatuses() {
            var vm = this
            this.$store.getters.permitStatuses.forEach(item => {
                //window.console.log(item)
                vm.permitStatuses.push(item)
            })
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
        this.notification_uuid = this.$route.params.id
    },

    mounted() {
        var vm = this
        //this.restriction_start_date = getCurrentDate()
        //this.restriction_end_date = getCurrentDate()

        this.getResources()
    }
}   

</script>

<style scoped>

</style>